<template>
  <b-modal
    id="payment-method-modal"
    ref="payment-method-modal"
    size="lg"
    v-on="$listeners"
    @hidden="onClose"
  >
    <template v-slot:modal-title>
      Payment Method
    </template>
    <template v-slot:modal-footer>
      <payment-method-form
        v-bind="$props"
        v-on="$listeners"
        @badCardIssuer="emitBadCardIssuer"
      />
    </template>
  </b-modal>
</template>

<script>
// This is primarily a pass through that houses the form in a modal
export default {
  name: 'PaymentMethodModal',
  components: {
    PaymentMethodForm: () => import('./PaymentMethodForm'),
  },
  props: {
    fromPaymentMethodsPage: Boolean,
    selectedAddress: null,
  },
  methods: {
    emitBadCardIssuer() {
      this.$emit('badCardIssuer')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .modal-header {
  background: white !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    color: #1F1F26;
  }
}
::v-deep .modal-title {
  padding: 0.5rem 0;
  font-weight: 600;
  color: #4E4E52;
}

  ::v-deep .modal-content {
    //background-color: transparent !important;
    background-color: #F1F1F1 !important;
    padding: 0 !important;
  }

  ::v-deep .modal-body {
    padding: 0 !important;
  }

  ::v-deep .modal-footer {
    width: 100%;
    padding: 0 !important;
  }
</style>

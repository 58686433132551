var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    _vm._g(
      {
        ref: "payment-method-modal",
        attrs: { id: "payment-method-modal", size: "lg" },
        on: { hidden: _vm.onClose },
        scopedSlots: _vm._u([
          {
            key: "modal-title",
            fn: function () {
              return [_vm._v("\n    Payment Method\n  ")]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c(
                  "payment-method-form",
                  _vm._g(
                    _vm._b(
                      { on: { badCardIssuer: _vm.emitBadCardIssuer } },
                      "payment-method-form",
                      _vm.$props,
                      false
                    ),
                    _vm.$listeners
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }